import React from 'react';
import { graphql } from 'gatsby';
import { PageWithSubNav, PageHero, PlatformTabs } from 'docComponents';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from './platforms/_index.js';
import pageHeroData from '../../../../data/pages/resources.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="design" title="Shadows" subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Code Variables"
        tierThree="Shadows"
      />

      <PlatformTabs>
        <WebTab data={data} type="web" />
        <ReactNativeTab type="reactnative" />
        <AndroidTab type="android" />
        <AppleTab type="apple" />
        <OtherTab type="other" />
      </PlatformTabs>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query shadowsResources {
    allShadowsYaml {
      edges {
        node {
          shadows {
            name
            sass_var
          }
        }
      }
    }
  }
`;
