import React from 'react';
import {
  PlatformTab,
  Section,
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
} from 'docComponents';

const WebTab = ({ data }) => {
  return (
    <PlatformTab>
      <Section>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="halves">
              <th>Name</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {data.allShadowsYaml.edges[0].node.shadows.map(item => {
              return (
                <DataTableRow layout="halves" key={item.sass_var}>
                  <DataTableValueBlock>{item.name}</DataTableValueBlock>
                  <DataTableCodeBlock>{item.sass_var}</DataTableCodeBlock>
                </DataTableRow>
              );
            })}
          </DataTableBody>
        </DataTable>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
